import { Component, OnInit } from '@angular/core';
import { LayoutMenuService, LayoutTableService, MIAFilter } from '@mobileia/layout-elite';
import { FVEventService, FVFilter } from '../../../../../base/src/public_api';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { RfidService } from '../../services/rfid.service';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {

  accounts : any;
  events = [];
  filter = new MIAFilter();
  filterEvent = -1;
  filterProvider = -1;
  pageCurrent = 1;
  pages = [];
  isLoading = true;
  where = "";
  search = "";

  saleEditing : {};

  constructor(private menuService : LayoutMenuService,
    private tableService : LayoutTableService,
    private eventService : FVEventService,
  private rfidService : RfidService,
  private modalService: NgbModal) {
    
    this.menuService.activatedSidebar(4);
  }

  ngOnInit() {
    this.tableService.getFilterObservable().subscribe(filter => {
      this.onChangeFilter(filter);
    });
    this.loadAccounts();
  }

  loadAccounts(){
    this.isLoading = true;
    this.rfidService.fetchAllAccounts(this.pageCurrent, this.where, this.search, this.filter, response => {
      var pages = [];
      for (let i = 0; i < response.last_page; i++) {
        pages.push((i+1));
      }
      this.pages = pages;
      this.accounts = response;
      this.isLoading = false;
    });
  }

  onClickPage(page : number){
    this.pageCurrent = page;
    this.loadAccounts();
  }
  /**
   *  Cargar credito en una tarjeta
   * @param amount
   * @param account
   */
  onLoadCard(amount : string, account) {
    this.rfidService.loadMoney(account.id, amount, data => {
      this.loadAccounts();
    });
  }

  onLoadErrorCard(amount: string, account) {
    this.rfidService.addErrorMoney(account.id, amount).subscribe(data => {
      if (data.success) {
        account.amount -= parseFloat(amount);
      }
    });
  }

  onSetDiscount(discount: string, account) {
    this.rfidService.editDiscount(account.id, discount, data => {
      account.discount = discount;
    });
  }

  onResendTicket(email: string, sale) {
    /*this.rfidService.resendTicket(sale.id, email);
    if(email != ''){
      sale.email = email;
    }*/
  }

  onSendSearch(){
    this.loadAccounts();
  }

  onChangeWhere(){
    this.where = "";
    if(this.filterEvent > 0){
      this.where = "sales.event_id:" + this.filterEvent;
    }
    if(this.filterProvider != -1){
      if(this.where == ''){
        this.where = "sales.provider_id:" + this.filterProvider;
      }else{
        this.where += ";sales.provider_id:" + this.filterProvider;
      }
    }
    this.loadAccounts();
  }

  onChangeFilter(newFilter){
    // Verificar si se seleccinoo un filtro correcto
    if(newFilter.title == '' || newFilter.title == undefined){
      return;
    }
    // Asignamos nuevo filtro
    this.filter = newFilter;
    // Reiniciar paginas
    this.pageCurrent = 1;
    // Volver a cargar los eventos
    this.loadAccounts();
  }

  openChangePerformance(content, sale) {
    this.saleEditing = sale;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if(result == '100'){
        
      }
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    return false;
  }

  onClickExport() {
    this.rfidService.fetchAllAccountsForExport(this.where, this.search, this.filter).subscribe(data => {
      if (!data.success) {
        return;
      }
      let exportVar = new Angular5Csv(
        data.response,
        'Tarjetas/Pulseras',
        { showLabels: false, showTitle: false/*, headers: ["First Name", "Last Name", "ID"]*/});
    });
  }

  columnTable = [
    {title: 'ID', filter: 'num', class: 'text-nowrap'}, 
    {title: 'Titulo', filter: 'title'}, 
    {title: 'Nombre', filter: 'mia_user.firstname'}, 
    {title: 'Apellido', filter: 'mia_user.lastname'},
    {title: 'Email', filter: 'email'},
    { title: 'Monto', filter: 'amount' },
    {title: 'Descuento', filter: 'discount'},
    {title: 'Acciones', class: 'text-nowrap'}
  ];
}
