import { Component, OnInit, ViewChild } from '@angular/core';
import { FVEvent, FVEntry, FVPerformance } from '../../../../../base/src/public_api';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { MiaFileService } from '@mobileia/core';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { environment } from 'src/environments/environment';
import { PrinterService } from '../../services/printer.service';

@Component({
  selector: 'app-print-ticket',
  templateUrl: './print-ticket.component.html',
  styleUrls: ['./print-ticket.component.scss']
})
export class PrintTicketComponent implements OnInit {

  @ViewChild('errorSwal') private errorSwal: SwalComponent;
  event : FVEvent = new FVEvent();
  tickets : Array<FVEntry> = Array<FVEntry>(new FVEntry());
  startDate;
  startTime;

  constructor(
    private fileService : MiaFileService,
  private printService : PrinterService) { }

  ngOnInit() {
  }

  onClickSave(){
    if(this.event.title == ""){
      this.errorSwal.text = "Debe ingresar el nombre del evento, esto es muy importante.";
      this.errorSwal.show();
      return;
    }if(this.event.address == ""){
      this.errorSwal.text = "Seleccionar una dirección desde el campo de Google Maps, asi obtenemos la coordenadas del lugar.";
      this.errorSwal.show();
      return;
    }if(this.event.place == ""){
      this.errorSwal.text = "Ingresa el nombre del lugar.";
      this.errorSwal.show();
      return;
    }if(this.startDate == undefined || this.startTime == undefined){
      this.errorSwal.text = "Selecciona la fecha del evento";
      this.errorSwal.show();
      return;
    }if(this.tickets[0].title == ""){
      this.errorSwal.text = "Carga al menos un ticket";
      this.errorSwal.show();
      return;
    }

    var performance = new FVPerformance();
    performance.start_date = this.startDate.year + "-" + this.startDate.month + "-"+ this.startDate.day + " " + this.startTime.hour + ":" + this.startTime.minute + ":00";

    this.printService.sendRequest(this.event, performance, this.tickets, response => {

    });
  }

  public handleAddressChange(address: Address) {
    // Guardamos datos obtenidos
    this.event.address = address.formatted_address;
    this.event.latitude = address.geometry.location.lat();
    this.event.longitude = address.geometry.location.lng()
  }
  /**
   * Funcion para subir la imagen.
   * @param files
   * @param field 
   */
  onUpload(files, field) {
    // Subir archivo
    this.fileService.upload(environment.mobileiaLabAppId, files).subscribe(data => {
      if(data.success){
        this.event.photo = "https://files.mobileia.com/" + data.response[0].path;
      }
    });;
  }

  removePhoto(){
    this.event.photo = "";
  }

  onClickRemoveTicket(ticket){
    if(this.tickets.length == 1){
      return false;
    }

    var indexOf = this.tickets.indexOf(ticket);
    this.tickets.splice(indexOf, 1);

    return false;
  }

  onClickAddTicket(){
    this.tickets.push(new FVEntry());
  }
}
