export const environment = {
  production: true,
  mobileiaLabAppId: "16",
  googleApiKey: "AIzaSyDB_RBCm9AuKRmD1rnJkhIb4FTgf6mQv_I",


  EVENT_STATUS_REVIEW: 1,
  EVENT_STATUS_APPROB: 2,
  EVENT_STATUS_SUSPENDED: 3,
  EVENT_STATUS_ENDED: 4,

  TICKET_CURRENCY_ARS: 0,
  TICKET_CURRENCY_USD: 1
};
