import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MiaDynamicFormService } from '@mobileia/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RfidService } from '../../services/rfid.service';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss']
})
export class CreateProductComponent implements OnInit {

  form: FormGroup;
  categories = [];
  productId = 0;
  product = {id: -1, title: '', price: '', stock_perfom: '1', category_id: -1 };

  public fields: any[] = [
    {
      type: 'select',
      name: 'category_id',
      label: 'Categoría',
      value: '',
      required: true,
      options: []
    },
    {
      type: 'text',
      name: 'title',
      label: 'Nombre',
      value: '',
      required: true,
    },
    {
      type: 'text',
      name: 'price',
      label: 'Precio',
      value: '',
      required: false,
    },
    {
      type: 'text',
      name: 'stock_perfom',
      label: 'Rendimiento del Stock (1 Botella = 5 tragos)',
      value: '1',
      required: false,
    },
    {
      type: 'button',
      name: 'save',
      label: 'Guardar'
    }
  ];

  constructor(private dynamicFormService: MiaDynamicFormService,
    private router: Router,
    private rfidService: RfidService,
    private route: ActivatedRoute) {

      this.route.params.pipe(concatMap(params => {
        this.productId = params['id'];
        return this.rfidService.fetchCategories();
      })).subscribe(data => {
        this.categories = data.response;

        this.categories.forEach(cat => {
          this.fields[0].options.push({ key: cat.id, label: cat.title });
        });

        this.product = this.rfidService.getProductById(this.productId);

        if (this.product == null && this.productId > 0) {
          return this.router.navigate(['/productos']);
        } else if (this.product == null) {
          // Preparamos formulario
        this.form = this.dynamicFormService.generateFormGroup(this.fields);
          return;
        }

        this.fields[0].value = this.product.category_id;
        this.fields[1].value = this.product.title;
        this.fields[2].value = this.product.price;
        this.fields[3].value = this.product.stock_perfom;

        // Preparamos formulario
        this.form = this.dynamicFormService.generateFormGroup(this.fields);
      });

  }

  ngOnInit() {
  }

  onSend(values) {
    console.log(values);
    let productId = -1;
    if (this.product != null) {
      productId = this.product.id;
    }

    this.rfidService.edit(productId, values.title, values.price, values.stock_perfom, values.category_id, data => {
      this.router.navigate(['/productos']);
    });
  }

}
