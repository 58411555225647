import { Component, OnInit } from '@angular/core';
import { FVEvent } from '../../../../../base/src/public_api';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit {

  event : FVEvent = new FVEvent();
  progress = 0;
  step = 0; // 0:  Visibilidad, 1: Tipo de ticket, 2: Información del evento, 3: Fecha del evento

  constructor() { }

  ngOnInit() {
  }

  /**
   * Funcion que se ejecuta al selecconar la visibilidad del evento
   */
  selectVisibility(){
    this.progress += 5;
    this.nextStep();
  }
  /**
   * Funcion que se ejecuta al seleccionar el tipo de ticket
   */
  selectTypeTicket(){
    this.progress += 5;
    this.nextStep();
  }
  /**
   * Fincion que se ejecuta al completar los datos del eventop
   */
  completedData(){
    this.progress += 40;
    this.nextStep();
  }
  /**
   * Funcion que se ejecuta al configurar las fechas del evento
   */
  selectPerformances(){
    this.progress += 10;
    this.nextStep();
  }
  /**
   * Funcion que se ejecuta al querer volver atras
   */
  onBack(){
    this.step--;
    if(this.step == 0){
      this.event.visibility = -1;
      this.progress -= 5;
    }else if(this.step == 1){
      this.event.type_ticket = -1;
      this.progress -= 5;
    }else if(this.step == 2){
      this.progress -= 40;
    }else if(this.step == 3){
      this.progress -= 10;
    }
  }
  /**
   * Funcion  que se ejecuta para continuar al siguiente paso
   */
  nextStep(){
    this.step++;
  }
}
