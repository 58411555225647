import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseStepComponent } from '../base-step.component';
import { FVEvent, FVCategory } from '../../../../../../base/src/public_api';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { MiaFileService } from '@mobileia/core';
import { environment } from '../../../../../../backend/src/environments/environment.prod';
import { CropperComponent } from 'angular-cropperjs';

@Component({
  selector: 'create-step-data',
  templateUrl: './step-data.component.html',
  styleUrls: ['./step-data.component.scss']
})
export class StepDataComponent extends BaseStepComponent implements OnInit {

  @ViewChild('errorSwal') private errorSwal: SwalComponent;
  categories = FVCategory.getCategories();

  config = {aspectRatio: 700/300 };
  imageUrl = '';
  @ViewChild('angularCropper') public angularCropper: CropperComponent;
  isEditingPhoto = false;

  constructor(private fileService: MiaFileService) { super(); }

  ngOnInit() {
  }

  clickSaveImageCrop() {
    this.onUpload([this.dataURLtoFile(this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg'), 'photo.jpg')], '');
  }

  clickSaveData() {
    // TODO: volver a poner
    /*if(this.event.title == ""){
      this.errorSwal.text = "Debe ingresar el nombre del evento, esto es muy importante.";
      this.errorSwal.show();
      return;
    }else if(!this.hasSelectedOneCategory()){
      this.errorSwal.text = "Debe seleccionar al menos una categoría.";
      this.errorSwal.show();
      return;
    }if(this.event.caption == ""){
      this.errorSwal.text = "Ingresa una descripción del evento.";
      this.errorSwal.show();
      return;
    }if(this.event.address == ""){
      this.errorSwal.text = "Seleccionar una dirección desde el campo de Google Maps, asi obtenemos la coordenadas del lugar.";
      this.errorSwal.show();
      return;
    }if(this.event.place == ""){
      this.errorSwal.text = "Ingresa el nombre del lugar.";
      this.errorSwal.show();
      return;
    }*/

    this.onChange.emit();
  }
  /**
   * Funcion que verifica si se selecciono una categoría
   */
  hasSelectedOneCategory() : boolean {
    for (let i = 0; i < this.categories.length; i++) {
      const category = this.categories[i];
      if(category.is_selected == 1){
        return true;
      }  
    }

    return false;
  }

  selectCategory(category : FVCategory){
    if(category.is_selected == 1){
      category.is_selected = 0;
    }else{
      category.is_selected = 1;
    }
  }

  public handleAddressChange(address: Address) {
    // Guardamos datos obtenidos
    this.event.address = address.formatted_address;
    this.event.latitude = address.geometry.location.lat();
    this.event.longitude = address.geometry.location.lng()
  }
  /**
   * Funcion para subir la imagen.
   * @param files
   * @param field
   */
  onUpload(files, field) {
    // Subir archivo
    this.fileService.upload(environment.mobileiaLabAppId, files).subscribe(data => {
      if (data.success) {
        this.imageUrl = 'https://files.mobileia.com/' + data.response[0].path;
        this.event.photo = 'https://files.mobileia.com/' + data.response[0].path;
        this.isEditingPhoto = false;
      }
    });
  }

  removePhoto() {
    this.event.photo = '';
  }

  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
}
}
