import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FVEvent, FVEntry, FVPerformance } from '../../../../base/src/public_api';
import { Constants } from '../../../../base/src/lib/constants';
import { ApiResponse } from '@mobileia/core';
import { AuthenticationService } from '@mobileia/authentication';

@Injectable({
  providedIn: 'root'
})
export class PrinterService {

  constructor(private http: HttpClient, private authService : AuthenticationService) { }

  public sendRequest(event : FVEvent, performance: FVPerformance, tickets : Array<FVEntry>, callback : (data: boolean)=> void){
    this.authService.getAccessToken().subscribe(token => {
        this.requestPrint(token, event, performance, tickets, callback);
    });
  }

  private requestPrint(access_token: string, event : FVEvent, performance: FVPerformance, tickets : Array<FVEntry>, callback : (data: boolean)=> void){
    this.http.post<ApiResponse<boolean>>(Constants.BASE_URL_NEW + 'print/request', {
      access_token : access_token, 
      title: event.title, 
      address: event.address,
      latitude: event.latitude,
      longitude: event.longitude,
      place: event.place,
      start_date: performance.start_date,
      photo: event.photo,
      tickets: tickets
    }).subscribe(data => {
        if(data.success){
          // Llamamos al callback
          callback(data.response);
        }
    });
  }
}
