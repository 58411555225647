import { Component, OnInit } from '@angular/core';
import { LayoutMenuService, LayoutTableService, MIAFilter } from '@mobileia/layout-elite';
import { FVEventService, FVFilter } from '../../../../../base/src/public_api';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { RfidService } from '../../services/rfid.service';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';

@Component({
  selector: 'app-moves',
  templateUrl: './moves.component.html',
  styleUrls: ['./moves.component.scss']
})
export class MovesComponent implements OnInit {

  sales: any;
  events = [];
  filter = new MIAFilter();
  filterEvent = -1;
  filterProvider = -1;
  pageCurrent = 1;
  pages = [];
  isLoading = true;
  where = '';
  search = '';

  saleEditing: {};

  statLiquidationTotal = 0;

  constructor(private menuService: LayoutMenuService,
    private tableService: LayoutTableService,
    private eventService: FVEventService,
  private rfidService: RfidService,
  private modalService: NgbModal) {
    
    this.menuService.activatedSidebar(5);
  }

  ngOnInit() {
    this.tableService.getFilterObservable().subscribe(filter => {
      this.onChangeFilter(filter);
    });
    this.loadEvents();
    this.loadSales();
    this.loadStats();
  }

  loadStats() {
    this.rfidService.fetchStats().subscribe(data => {
      if (!data.success) {
        return;
      }

      this.statLiquidationTotal = data.response.total_liquidation;
    });
  }

  loadEvents() {
    var filter = new FVFilter();
    filter.title = "title";
    filter.asc = 1;
    this.eventService.fetchAll(filter, data => {
      this.events = data;
    });
  }

  loadSales(){
    this.isLoading = true;
    this.rfidService.fetchAllTransactions(this.pageCurrent, this.where, this.search, this.filter, response => {
      var pages = [];
      for (let i = 0; i < response.last_page; i++) {
        pages.push((i+1));
      }
      this.pages = pages;
      this.sales = response;
      this.isLoading = false;
    });
  }

  onClickPage(page : number){
    this.pageCurrent = page;
    this.loadSales();
  }

  onSendSearch(){
    this.loadSales();
  }

  onChangeWhere(){
    this.where = "";
    if(this.filterEvent > 0){
      this.where = "sales.event_id:" + this.filterEvent;
    }
    if(this.filterProvider != -1){
      if(this.where == ''){
        this.where = "sales.provider_id:" + this.filterProvider;
      }else{
        this.where += ";sales.provider_id:" + this.filterProvider;
      }
    }
    this.loadSales();
  }

  onChangeFilter(newFilter){
    // Verificar si se seleccinoo un filtro correcto
    if(newFilter.title == '' || newFilter.title == undefined){
      return;
    }
    // Asignamos nuevo filtro
    this.filter = newFilter;
    // Reiniciar paginas
    this.pageCurrent = 1;
    // Volver a cargar los eventos
    this.loadSales();
  }

  onClickExport() {
    this.rfidService.fetchAllTransactionsForExport(this.where, this.search, this.filter).subscribe(data => {
      if (!data.success) {
        return;
      }
      let exportVar = new Angular5Csv(
        data.response,
        'Transacciones',
        { showLabels: false, showTitle: false/*, headers: ["First Name", "Last Name", "ID"]*/});
    });
  }

  onClickExportItems() {
    this.rfidService.fetchAllItemsTransactionsForExport().subscribe(data => {
      if (!data.success) {
        return;
      }
      let exportVar = new Angular5Csv(
        data.response,
        'Items de las transacciones',
        { showLabels: false, showTitle: false/*, headers: ["First Name", "Last Name", "ID"]*/});
    });
  }
}
