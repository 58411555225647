import { Component, OnInit } from '@angular/core';
import { FVFilter, FVEventService } from '../../../../../base/src/public_api';
import { LayoutMenuService, LayoutTableService } from '@mobileia/layout-elite';
import { RfidService } from '../../services/rfid.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  products = [];
  isLoading = true;

  productSelect = null;
  numberCard: '';
  quantity: '';
  stock: '';

  constructor(
    private menuService: LayoutMenuService,
    private tableService: LayoutTableService,
    private rfidService: RfidService,
    private modalService: NgbModal) {
      this.menuService.activatedSidebar(3);
  }

  ngOnInit() {
    this.loadProducts();
  }

  loadProducts() {
    this.isLoading = true;
    this.rfidService.getProducts(data => {
      this.products = data;
      this.isLoading = false;
    });
  }

  sendGift() {
    this.rfidService.addGift(this.numberCard, this.productSelect.id, this.quantity, data => {
      this.quantity = '';
      this.numberCard = '';
    });
  }

  sendStock() {
    this.rfidService.editStock(this.productSelect.id, this.stock, data => {
      this.productSelect.stock += parseFloat(this.stock);
      this.stock = '';
    });
  }

  openModalStock(modal, product): boolean {
    this.productSelect = product;
    this.modalService.open(modal, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        if (result == '100') {
          this.sendStock();
        }
      }, (reason) => {
      });
      return false;
  }

  openModalAddGift(modal, product): boolean {
    this.productSelect = product;
    this.modalService.open(modal, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        if (result == '100') {
          this.sendGift();
        }
      }, (reason) => {
      });
      return false;
    }

}
