import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FVEvent, FVEntry, FVPerformance } from '../../../../base/src/public_api';
import { Constants } from '../../../../base/src/lib/constants';
import { ApiResponse } from '@mobileia/core';
import { AuthenticationService, MiaAuthHttpService } from '@mobileia/authentication';
import { MIAFilter } from '@mobileia/layout-elite';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RfidService extends MiaAuthHttpService {

  private products = [];

  constructor(protected http: HttpClient, protected authService: AuthenticationService) {
    super(http, authService);
  }
  /**
   * Obtener un reporte
   */
  public fetchReportById(reportId: number): Observable<ApiResponse<any>> {
    return this.postAuthObject(Constants.BASE_URL_NEW + 'rfid/report-detail', { id: reportId });
  }
  /**
   * Genera un reporte
   */
  public generateReport(): Observable<ApiResponse<any>> {
    return this.postAuthObject(Constants.BASE_URL_NEW + 'rfid/report/generate', {});
  }
  
  /**
   * Obtener estadisticas del store en RFID
   */
  public fetchAllReports(): Observable<ApiResponse<[any]>> {
    return this.postAuthArray(Constants.BASE_URL_NEW + 'rfid/reports', {});
  }
  /**
   * Obtener estadisticas del store en RFID
   */
  public fetchStats(): Observable<ApiResponse<any>> {
    return this.postAuthObject(Constants.BASE_URL_NEW + 'rfid/stats', {});
  }
  /**
   * Obtener todas las categorias del Store
   */
  public fetchCategories(): Observable<ApiResponse<[any]>> {
    return this.postAuthArray(Constants.BASE_URL_NEW + 'rfid/get-categories', {});
  }

  public addCategory(title: string): Observable<ApiResponse<true>> {
    return this.postAuthObject(Constants.BASE_URL_NEW + 'rfid/edit-category', { id: 0, title: title});
  }

  public addGift(cardId: string, productId: number, quantity: string, callback: (data: boolean) => void) {
    this.requestAuthObject(Constants.BASE_URL_NEW + 'rfid/set-gift',
    { card_id: cardId, product_id: productId, quantity: quantity }, callback);
  }

  public editStock(productId: number, amount: string, callback: (data: boolean) => void) {
    this.requestAuthObject(Constants.BASE_URL_NEW + 'rfid/edit-stock',
    { id: productId, amount: amount }, callback);
  }

  public editDiscount(cardId: number, discount: string, callback: (data: boolean) => void) {
    this.requestAuthObject(Constants.BASE_URL_NEW + 'rfid/set-discount',
    { card_id: cardId, discount: discount }, callback);
  }

  public addErrorMoney(cardId: number, amount: string): Observable<ApiResponse<any>> {
    return this.postAuthObject(Constants.BASE_URL_NEW + 'rfid/error-load-money', { card_id: cardId, amount: amount });
  }

  public fetchAllTransactions(page: number, where: string, search: string, filter: MIAFilter, callback: (data: any) => void) {
    this.requestAuthObject(Constants.BASE_URL_NEW + 'rfid/transactions',
    { filter: filter.title, asc: filter.asc, page: page, where: where, search: search }, callback);
  }

  public fetchAllTransactionsForExport(where: string, search: string, filter: MIAFilter): Observable<ApiResponse<any>> {
    return this.postAuthObject(
      Constants.BASE_URL_NEW + 'rfid/transactions',
      { filter: filter.title, asc: filter.asc, where: where, search: search, export: 1});
  }

  public fetchAllItemsTransactionsForExport(): Observable<ApiResponse<any>> {
    return this.postAuthObject(
      Constants.BASE_URL_NEW + 'rfid/export-items',
      { export: 1});
  }



  public edit(id: number, title: string, price: string, stockPerfom: string, categoryId: number, callback: (data: any) => void){
    this.authService.getAccessToken().subscribe(token => {
      this.requestEdit(token, id, title, price, stockPerfom, categoryId, callback);
  });
  }

  private requestEdit(access_token: string, id: number, title: string, price: string, stockPerfom: string, categoryId: number, callback: (data: any) => void){
    this.http.post<ApiResponse<[any]>>(Constants.BASE_URL_NEW + 'rfid/edit-product', {
      access_token : access_token, id: id, title: title,
      price: price, stock_perfom: stockPerfom, category_id: categoryId}).subscribe(data => {
      if(data.success) {
          callback(data.response);
      }
    });
  }

  public loadMoney(id: number, price: string, callback: (data: any) => void){
    this.authService.getAccessToken().subscribe(token => {
      this.requestLoadMoney(token, id, price, callback);
  });
  }

  private requestLoadMoney(access_token: string, id: number, price: string, callback: (data: any) => void){
    this.http.post<ApiResponse<any>>(Constants.BASE_URL_NEW + 'rfid/load-money', {access_token : access_token, card_id: id, amount: price}).subscribe(data => {
      if(data.success){
          callback(data.response);
      }
    });
  }

  public fetchAllAccountsForExport(where: string, search: string, filter: MIAFilter): Observable<ApiResponse<any>> {
    return this.postAuthObject(
      Constants.BASE_URL_NEW + 'rfid/accounts',
      { filter: filter.title, asc: filter.asc, where: where, search: search, export: 1});
  }

  /**
     * Devuelve todos los eventos asignado al usuario
     * @param callback 
     */
    public fetchAllAccounts(page : number, where : string, search : string, filter : MIAFilter, callback : (data: any)=> void){
      this.authService.getAccessToken().subscribe(token => {
          this.requestFetchAllAccounts(token, page, where, search, filter, callback);
      });
    }

    private requestFetchAllAccounts(access_token: string, page : number, where : string, search : string, filter : MIAFilter, callback : (data: any)=> void){
      this.http.post<ApiResponse<[any]>>(Constants.BASE_URL_NEW + 'rfid/accounts', {access_token : access_token, filter: filter.title, asc: filter.asc, page: page, where: where, search: search}).subscribe(data => {
          if(data.success){
              callback(data.response);
          }
      });
    }

  public getProducts(callback: (data: [any]) => void) {
    this.authService.getAccessToken().subscribe(token => {
        this.requestGetProducts(token, callback);
    });
  }

  private requestGetProducts(access_token: string, callback : (data: [any])=> void){
    this.http.post<ApiResponse<[any]>>(Constants.BASE_URL_NEW + 'rfid/get-products', {
      access_token : access_token
    }).subscribe(data => {
        if(data.success){
          // Guardamos eventos cargados en el servicio
          this.products = data.response;
          // Llamamos al callback
          callback(data.response);
        }
    });
  }

  public getProductById(id : number) : any {
    for (let i = 0; i < this.products.length; i++) {
      const company = this.products[i];
      if(company.id == id){
        return company;
      }
    }
    return null;
  }
}
