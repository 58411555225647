import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@mobileia/authentication';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss']
})
export class AccessComponent implements OnInit {

  constructor(private router: Router,
    private route: ActivatedRoute,
  private authService : AuthenticationService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      var accessToken = params['token'];
      var redirect = params['redirect'];

      // Guardar token
      this.authService.saveAccesstoken(accessToken);
      // ir a la pagina 
      this.openRedirect(redirect);
    });
  }

  openRedirect(redirect){
    this.router.navigateByUrl('/' + redirect);
  }
}
