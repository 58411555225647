import { Component, OnInit } from '@angular/core';
import { LayoutMenuService, LayoutTableService } from '@mobileia/layout-elite';
import { RfidService } from '../../services/rfid.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  isLoading = true;
  reports = [];

  constructor(
    private menuService: LayoutMenuService,
    private tableService: LayoutTableService,
    private rfidService: RfidService,
    private modalService: NgbModal) {
      this.menuService.activatedSidebar(6);
  }

  ngOnInit() {
    this.loadReports();
  }

  clickGenerate() {
    this.rfidService.generateReport().subscribe(data => {
      if (!data.success) {
        return;
      }
      this.reports.unshift(data.response);
    });
  }

  loadReports() {
    this.isLoading = true;
    this.rfidService.fetchAllReports().subscribe(data => {
      if (!data.success) {
        return;
      }
      this.reports = data.response;
      this.isLoading = false;
    });
  }
}
