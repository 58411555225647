import { Component, OnInit } from '@angular/core';
import { FVEventService } from 'projects/base/src/public_api';
import { LayoutMenuService, MIAFilter } from '@mobileia/layout-elite';
import { TouchSequence } from 'selenium-webdriver';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  events = { data: [] };
  filter = new MIAFilter();
  filterEvent = -1;
  filterProvider = -1;
  pageCurrent = 1;
  pages = [];
  isLoading = true;
  where = "";
  search = "";

  constructor(private menuService: LayoutMenuService,
    private eventService: FVEventService) {
      this.menuService.activatedSidebar(2);
     }

  ngOnInit() {
    this.loadEvents();
  }

  loadEvents() {
    this.eventService.myEvents(this.pageCurrent, this.where, this.search, this.filter, response => {
      var pages = [];
      for (let i = 0; i < response.last_page; i++) {
        pages.push((i+1));
      }
      this.pages = pages;
      this.events = response;
      this.isLoading = false;
    });
  }

  clickEvent(event) {
    if (event.open === 1) {
      event.open = 0;
    } else {
      event.open = 1;
    }
  }
}
