import { Component, OnInit } from '@angular/core';
import { MiaDynamicFormService } from '@mobileia/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RfidService } from '../../services/rfid.service';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.scss']
})
export class ReportDetailComponent implements OnInit {

  reportId = 0;
  report = {};

  constructor(
    private dynamicFormService: MiaDynamicFormService,
    private router: Router,
    private rfidService: RfidService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.pipe(concatMap(params => {
      this.reportId = params['id'];
      return this.rfidService.fetchReportById(this.reportId);
    })).subscribe(data => {
      if (data.success) {
        this.report = data.response;
      }
    });
  }

}
