import { Component, OnInit } from '@angular/core';
import { BaseStepComponent } from '../base-step.component';
import { FVEvent } from '../../../../../../base/src/public_api';

@Component({
  selector: 'create-step-type',
  templateUrl: './step-type.component.html',
  styleUrls: ['./step-type.component.scss']
})
export class StepTypeComponent extends BaseStepComponent implements OnInit {

  constructor() { super(); }

  ngOnInit() {
  }

  clickDigital(){
    this.event.type_ticket = FVEvent.TYPE_TICKET_DIGITAL;
    this.onChange.emit();
  }

  clickPhysical(){
    this.event.type_ticket = FVEvent.TYPE_TICKET_PHYSICAL;
    this.onChange.emit();
  }
}
