import { Component } from '@angular/core';
import { AuthenticationService } from '@mobileia/authentication';
import { Router } from '@angular/router';
import { LayoutMenuService } from '@mobileia/layout-elite';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  isLogged = false;
  isLoading = true;

  topbarMenu = [/*{
    id: 1,
    title: 'Mi perfil',
    icon: ''
  },
  {
    id: 2,
    title: 'Ejemplo 2',
    icon: ''
  },
  {
    separator: true
  },
  {
    id: 3,
    title: 'Mi perfil',
    icon: 'fa fa-user'
  },
  {
    separator: true
  },*/
  {
    id: 4,
    title: 'Salir',
    icon: 'fa fa-power-off'
  }];

  sidebarMenu = [
    /*{
      is_group: true,
      title: '--- MENU PRINCIPAL'
    },
    {
      id: 1,
      title: 'Dashboard',
      icon: 'fas fa-tachometer-alt',
      is_active: true
    },
    {
      id: 2,
      title: 'Eventos',
      icon: 'fas fa-tachometer-alt',
      budge: '4'
    },*/
    {
      is_group: true,
      title: '--- RFID'
    },
    {
      id: 4,
      title: 'Tarjetas',
      icon: 'fas fa-tachometer-alt',
      is_active: true
    },
    {
      id: 7,
      title: 'Categorias',
      icon: 'fas fa-tachometer-alt',
      is_active: true
    },
    {
      id: 3,
      title: 'Productos',
      icon: 'fas fa-tachometer-alt',
      is_active: true
    },
    {
      id: 5,
      title: 'Transacciones',
      icon: 'fas fa-tachometer-alt',
      is_active: true
    },
    {
      id: 6,
      title: 'Reportes',
      icon: 'fas fa-tachometer-alt',
      is_active: true
    },
  ];

  constructor(private authService: AuthenticationService,
    private router: Router,
    private menuService: LayoutMenuService) {
      this.authService.isLoggedBehavior().subscribe(logged => {
        this.isLogged = logged;
      });

      this.menuService.getSidebarMenuObservable().subscribe(id => {
        this.clickSidebarMenu(id);
      });

      this.menuService.getTopbarMenuObservable().subscribe(id => {
        this.clickTopbarMenu(id);
      });
     }

  public clickTopbarMenu(id: number) {
    if (id == 4) {
      this.logout();
    }
  }

  public clickSidebarMenu(id: number) {
    if(id == 1) {
      this.router.navigateByUrl('/dashboard');
    } else if(id == 2) {
      this.router.navigateByUrl('/eventos');
    } else if(id == 3) {
      this.router.navigateByUrl('/productos');
    } else if(id == 4) {
      this.router.navigateByUrl('/tarjetas');
    } else if (id == 5) {
      this.router.navigateByUrl('/movimientos');
    } else if (id == 6) {
      this.router.navigateByUrl('/reportes');
    } else if (id == 7) {
      this.router.navigateByUrl('/categorias');
    }
  }

  /**
   * logout
   */
  public logout() {
    this.authService.signOut();
    this.router.navigate(['/login']);
    this.isLogged = false;
  }

  ngAfterContentInit(){
    // Terminar el loading
    this.isLoading = false;
  }
}
