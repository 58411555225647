import { Component, OnInit } from '@angular/core';
import { LayoutMenuService, LayoutTableService } from '@mobileia/layout-elite';
import { RfidService } from '../../services/rfid.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  categories: any[] = [];
  isLoading = true;

  categorySelect = null;

  constructor(
    private menuService: LayoutMenuService,
    private tableService: LayoutTableService,
    private rfidService: RfidService) {
      this.menuService.activatedSidebar(7);
  }

  ngOnInit() {
    this.loadProducts();
  }

  loadProducts() {
    this.isLoading = true;
    this.rfidService.fetchCategories().subscribe(data => {
      this.categories = data.response;
      this.isLoading = false;
    });
  }

  newCategory(title: string) {
    this.rfidService.addCategory(title).subscribe(data => {
      if (data.success) {
        window.location.reload();
      }
    });
  }
}
