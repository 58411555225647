import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseStepComponent } from '../base-step.component';
import { FVEvent } from '../../../../../../base/src/public_api';
import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'create-step-performance',
  templateUrl: './step-performance.component.html',
  styleUrls: ['./step-performance.component.scss']
})
export class StepPerformanceComponent extends BaseStepComponent implements OnInit {

  @ViewChild('modalPerformance') private modalPerformanceView: NgbModalRef;
  private modalPerformance;
  startDate;
  startTime;
  endDate;
  endTime;

  constructor(private modalService: NgbModal) { super(); }

  ngOnInit() {
  }

  clickSavePerformance(){
    console.log(this.startDate);
    console.log(this.endDate);
    console.log(this.startTime);
    console.log(this.endTime);
    this.modalPerformance.dismiss();
  }

  clickContinue(){
    
    this.onChange.emit();
  }

  clickNotPerformances(){
    this.event.has_performances = 0;
    this.openModal();
  }

  clickHasPerformances(){
    this.event.has_performances = 1;
  }

  openModal(){
    this.modalPerformance = this.modalService.open(this.modalPerformanceView, {backdrop: false});
  }
}
