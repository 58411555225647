import { OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FVEvent } from '../../../../../base/src/public_api';

export class BaseStepComponent implements OnInit {

    @Input() event: FVEvent;
    @Output() onChange = new EventEmitter();
    @Output() onBack = new EventEmitter();
  
    constructor() { }
  
    ngOnInit() {
    }
  
    clickBack(){
        this.onBack.emit();
        return false;
    }
  }
  