// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mobileiaLabAppId: "16",
  googleApiKey: "AIzaSyDB_RBCm9AuKRmD1rnJkhIb4FTgf6mQv_I",


  EVENT_STATUS_REVIEW: 1,
  EVENT_STATUS_APPROB: 2,
  EVENT_STATUS_SUSPENDED: 3,
  EVENT_STATUS_ENDED: 4,

  TICKET_CURRENCY_ARS: 0,
  TICKET_CURRENCY_USD: 1
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
