import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BaseModule } from 'projects/base/src/public_api';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthenticationModule, AuthGuard } from '@mobileia/authentication';
import { CommonModule } from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { LayoutEliteModule, LoginPageComponent } from '@mobileia/layout-elite';
import { EventsComponent } from './pages/events/events.component';
import { CreateEventComponent } from './pages/create-event/create-event.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgCircleProgressModule } from 'ng-circle-progress';
import { FormsModule } from '@angular/forms';
import { StepVisibilityComponent } from './pages/create-event/step-visibility/step-visibility.component';
import { StepTypeComponent } from './pages/create-event/step-type/step-type.component';
import { StepDataComponent } from './pages/create-event/step-data/step-data.component';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { StepPerformanceComponent } from './pages/create-event/step-performance/step-performance.component';
import { StepTicketComponent } from './pages/create-event/step-ticket/step-ticket.component';
import { PrintTicketComponent } from './pages/print-ticket/print-ticket.component';
import { AccessComponent } from './access/access.component';
import { ProductsComponent } from './pages/products/products.component';
import { CreateProductComponent } from './pages/create-product/create-product.component';
import { CoreModule } from '@mobileia/core';
import { AccountsComponent } from './pages/accounts/accounts.component';
import { MovesComponent } from './pages/moves/moves.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { SalesComponent } from './pages/sales/sales.component';
import { MomentModule } from 'ngx-moment';
import { ReportsComponent } from './pages/reports/reports.component';
import { ReportDetailComponent } from './pages/report-detail/report-detail.component';
import { CategoriesComponent } from './pages/categories/categories.component';

const routes: Routes = [
  { path: '', redirectTo: '/productos', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginPageComponent,
    data: { 'success_route' : 'productos' }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'eventos',
    component: EventsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'evento/ventas/:id',
    component: SalesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'crear-evento',
    component: CreateEventComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'imprimir-ticket',
    component: PrintTicketComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'access/:token/:redirect',
    component: AccessComponent,
  },
  {
    path: 'productos',
    component: ProductsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'productos/crear',
    component: CreateProductComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'productos/crear/:id',
    component: CreateProductComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tarjetas',
    component: AccountsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'movimientos',
    component: MovesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reportes',
    component: ReportsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reporte/:id',
    component: ReportDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'categorias',
    component: CategoriesComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    EventsComponent,
    CreateEventComponent,
    StepVisibilityComponent,
    StepTypeComponent,
    StepDataComponent,
    StepPerformanceComponent,
    StepTicketComponent,
    PrintTicketComponent,
    AccessComponent,
    ProductsComponent,
    CreateProductComponent,
    AccountsComponent,
    MovesComponent,
    SalesComponent,
    ReportsComponent,
    ReportDetailComponent,
    CategoriesComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    NgbModule.forRoot(),
    AuthenticationModule,
    LayoutEliteModule,
    BaseModule,
    CoreModule,
    RouterModule.forRoot(routes),
    GooglePlaceModule,
    NgCircleProgressModule.forRoot({}),
    SweetAlert2Module.forRoot(),
    AngularCropperjsModule,
    MomentModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
