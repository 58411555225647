import { Component, OnInit } from '@angular/core';
import { MIAFilter, LayoutMenuService, LayoutTableService } from '@mobileia/layout-elite';
import { FVEventService } from 'projects/base/src/public_api';
import { Router, ActivatedRoute } from '@angular/router';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';
import { MIATableModel } from './miatablemodel';
import { environment } from 'projects/producer/src/environments/environment.prod';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit {

  env = environment;

  eventId = 0;
  event = { id: 0, title: '' };
  sales = { data: [] };
  filter = new MIAFilter();
  filterPerformance = -1;
  filterRrpp = -1;
  filterStatus = -2;
  filterPayment = -1;
  filterProvider = -1;
  isLoading = true;
  tableModel = new MIATableModel();

  constructor(
    private route: ActivatedRoute,
    private menuService: LayoutMenuService,
    private eventService: FVEventService,
    private router: Router,
    private tableService: LayoutTableService) {
      this.menuService.activatedSidebar(5);
      this.tableService.getFilterObservable().subscribe(newFilter => {
        this.filter = newFilter;
        this.loadSales();
      });
     }

  ngOnInit() {
    this.route.params.subscribe(params => {
      // Obtenemos ID del evento
    this.eventId = params['id'];
    // Buscamos evento
    this.eventService.getEvent(this.eventId, event => {
      this.event = event;
      if (event == null) {
        this.router.navigate(['/eventos']);
        return;
      }
      this.event = event;
      this.tableModel.filters.ticket = -1;
      // Cargar tickets
      this.loadSales();
    });
  });
  }

  loadSales() {
    this.isLoading = true;
    this.eventService.getSales(this.eventId, this.tableModel.pageCurrent,
      this.tableModel.getWhere(), this.tableModel.search, this.filter, response => {
      this.tableModel.setPagination(response.last_page, 10);
      this.sales = response;
      this.isLoading = false;
    });
  }

  onChangeWhere() {
    this.tableModel.resetWhere();
    if (this.tableModel.filters.ticket > 0) {
      this.tableModel.addWhere('sales.ticket_id', this.tableModel.filters.ticket);
    }
    if (this.filterRrpp > 0) {
      this.tableModel.addWhere('sales.rrpp_id', this.filterRrpp);
    }
    if (this.filterStatus != -2) {
      this.tableModel.addWhere('sales.status', this.filterStatus);
    }
    if (this.filterProvider > -1) {
      this.tableModel.addWhere('sales.provider_id', this.filterProvider);
    }

    this.loadSales();
  }

  onClickPage(page: number) {
    this.tableModel.pageCurrent = page;
    this.loadSales();
  }

  onChangePerformance() {
    if (this.filterPerformance <= 0 || this.event.id === this.filterPerformance) {
      return;
    }

    this.router.navigate(['/evento/ventas/' + this.filterPerformance]);
  }

  onClickExport() {
    new Angular5Csv(this.sales.data, 'Ventas', {showLabels: false, showTitle: false, headers: ["First Name", "Last Name", "ID"]});
  }

  columnTable = [
    { title: 'Nombre', filter: 'sales.firstname', class: 'text-nowrap' },
    { title: 'Fecha', filter: 'sales.created_at' },
    { title: 'Ticket', filter: 'ticket.title' },
    { title: 'Promotor', filter: ''},
    { title: 'Estado', filter: 'sales.status' },
    { title: 'Forma Pago', filter: '' },
    { title: 'Precio', filter: 'sales.price' },
    { title: '', class: 'text-nowrap' }
  ];
}
