import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FVEvent } from '../../../../../../base/src/public_api';
import { BaseStepComponent } from '../base-step.component';

@Component({
  selector: 'create-step-visibility',
  templateUrl: './step-visibility.component.html',
  styleUrls: ['./step-visibility.component.scss']
})
export class StepVisibilityComponent extends BaseStepComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

  clickPublic(){
    this.event.visibility = FVEvent.VISIBILITY_PUBLIC;
    this.onChange.emit();
  }

  clickPrivate(){
    this.event.visibility = FVEvent.VISIBILITY_PRIVATE;
    this.onChange.emit();
  }
}
